export const List = [
  {
    name: '用户基本信息',
  },
  {
    name: '账号信息',
  },
  {
    name: '用户使用信息',
  },
  {
    name: '剪切板数据',
  },
  {
    name: '设备信息',
  },
];

export const kind = [
  [
    { name: '个人姓名' },
    { name: '头像' },
    { name: '昵称' },
    { name: '简介' },
    { name: '生日' },
    { name: '性别' },
    { name: '城市' },
    { name: '手机号码' },
  ],
  [{ name: '用户注册账号' }, { name: '第三方平台账号' }],
  [
    { name: '播放记录' },
    { name: '搜索记录' },
    { name: '下载记录' },
    { name: '发布记录' },
    { name: '交流互动记录' },
    { name: '订单记录' },
    { name: '提现记录' },
    { name: '支付记录' },
  ],
  [{ name: '剪切板的内容' }],
  [{ name: '设备标识信息' }, { name: '设备参数及系统信息' }],
];

export const content = [
  [
    {
      explain: '',
      goal: '实名认证；提现；活动参与；客户服务',
      scene: [{ sceneDesc: '用户提现时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '完善账户信息',
      scene: [{ sceneDesc: '设置/更改头像', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '完善账户信息',
      scene: [{ sceneDesc: '用户填写个人资料时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '账户管理',
      scene: [{ sceneDesc: '用户填写个人资料时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '完善账户信息',
      scene: [{ sceneDesc: '用户填写个人资料时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '完善账户信息',
      scene: [{ sceneDesc: '用户填写个人资料时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '完善账户信息；个性化推荐',
      scene: [{ sceneDesc: '用户填写个人资料时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '账号注册/登录；实名认证；发布内容；活动参与；营销推广；用户调研；消息通知；安全保障；客户服务',
      scene: [{ sceneDesc: '用户注册/登录时', collectInfo: '已收集x次/未收集', infoContent: '136******49' }],
    },
  ],
  [
    {
      explain: 'uid',
      goal: '账号注册/登录；安全保障',
      scene: [
        { sceneDesc: '用户注册/登录时', collectInfo: '未收集', infoContent: 'o********w', needReq: true, uid: true },
      ],
    },
    {
      explain: '包括openid/unionid等第三方平台标识符',
      goal: '账号注册/登录/提现；安全保障',
      scene: [{ sceneDesc: '用户注册/登录/提现时', collectInfo: '已收集x次/未收集', infoContent: 'o********w' }],
    },
  ],
  [
    {
      explain: '',
      goal: '音视频续播；展示播放记录；个性化推荐；安全保障；提供一致化、连续性的使用体验',
      scene: [
        {
          sceneDesc: '用户播放音视频内容时',
          collectInfo: '已收集x次/未收集',
          infoContent: '查看详情',
          url: `kuaiyin://personal`,
          needReq: true,
        },
      ],
    },
    {
      explain: '包括搜索行为及搜索内容',
      goal: '展示部分搜索记录；个性化推荐；安全保障；提供一致性、连续性的使用体验',
      scene: [{ sceneDesc: '用户搜索时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '展示下载记录；个性化推荐；安全保障；提供一致化、连续性的使用体验',
      scene: [
        {
          sceneDesc: '用户下载时',
          collectInfo: '已收集x次/未收集',
          infoContent: '查看详情',
          url: 'kuaiyin://down',
          needReq: true,
          reqParams: 'user.downloads',
        },
      ],
    },
    {
      explain: '不包括发布行为以及发布内容，如文字/图片/音视频内容信息',
      goal: '信息制作、发布；展示发布内容；个性化推荐；安全保障；客户服务；提供一致化、连续性的使用体验',
      scene: [
        {
          sceneDesc: '用户发布音视频时',
          collectInfo: '已收集x次/未收集',
          infoContent: '查看详情',
          url: `kuaiyin://personal`,
          needReq: true,
          reqParams: 'user.works',
        },
        { sceneDesc: '用户发布评论时', collectInfo: '已收集x次/未收集', infoContent: '无内容' },
        {
          sceneDesc: '用户在社区等发布信息时',
          collectInfo: '已收集x次/未收集',
          infoContent: '查看详情',
          url: `kuaiyin://personal`,
          needReq: true,
          reqParams: 'ugc.ugc_num',
        },
        { sceneDesc: '用户发布反馈信息时', collectInfo: '已收集x次/未收集', infoContent: '无内容' },
        {
          sceneDesc: '用户发布语音评论时',
          collectInfo: '已收集x次/未收集',
          infoContent: '查看详情',
          url: `kuaiyin://personal`,
          needReq: true,
          reqParams: 'comment.voice_comment_num',
        },
      ],
    },
    {
      explain: '包括互动行为及互动信息，如文字/图片/音视频信息',
      goal: '展示交互互动记录；个性化推荐；好友互动；安全保障；客户服务；提供一致化、连续性的使用体验',
      scene: [
        {
          sceneDesc: '用户进行收藏时',
          collectInfo: '已收集x次/未收集',
          infoContent: '查看详情',
          url: `kuaiyin://personal`,
          needReq: true,
        },
        {
          sceneDesc: '用户进行点赞时',
          collectInfo: '已收集x次/未收集',
          infoContent: '查看详情',
          url: `kuaiyin://personal`,
          needReq: true,
          reqParams: 'user.likes',
        },
        {
          sceneDesc: '用户进行关注时',
          collectInfo: '已收集x次/未收集',
          infoContent: '查看详情',
          url: 'kuaiyin://profile/follows',
          needReq: true,
          reqParams: 'user.follows',
        },
        {
          sceneDesc: '客户服务进行沟通、调查情况等涉及交流信息收集、使用的场景时',
          collectInfo: '已收集x次/未收集',
          infoContent: '无内容',
        },
      ],
    },
    {
      explain: '',
      goal: '展示订单；下单与订单管理；个性化推荐；安全保障',
      scene: [{ sceneDesc: '用户下单时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '展示提现记录；安全保障',
      scene: [
        {
          sceneDesc: '用户提现时',
          collectInfo: '已收集x次/未收集',
          infoContent: '查看详情',
          url: `kuaiyin://web?web_url=http://h5.kaixinyf.cn/withdraw_records`,
          needReq: true,
        },
      ],
    },
    {
      explain: '',
      goal: '展示交易记录；完成支付；安全保障',
      scene: [{ sceneDesc: '用户支付时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
  ],
  [
    {
      explain: '',
      goal: '活动参与；信息发布',
      scene: [
        {
          sceneDesc: '用户参与部分存在口令码（如邀请码）的活动时',
          collectInfo: '已收集x次/未收集',
          infoContent: '无内容',
        },
        { sceneDesc: '用户发布音视频时', collectInfo: '已收集x次/未收集', infoContent: '无内容' },
      ],
    },
  ],
  [
    {
      explain: 'IMEI/MEID/AndroidID/OpenUDID/IMSI/IDFA/IDFV/OAID/MAC地址及其它综合设备参数形成的设备标识符',
      goal: '提供音视频浏览、搜索、播放服务；安全保障；个性化推荐；广告统计分析与优化',
      scene: [{ sceneDesc: '用户冷启动App时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '设备类型、设备型号、设备品牌、分辨率，操作系统及硬件相关信息、语言设置、蓝牙等',
      goal: '提供音视频浏览、搜索、播放服务；安全保障',
      scene: [{ sceneDesc: '用户冷启动App时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
  ],
];
